export default {
  props: {
    listKey: String,
  },
  data: () => ({
    page: 1,
    pager: null,
    rawData: [],
    loading: false,
  }),
  methods: {
    exportConfirm() {
      if(this.exportExcelConfig.withoutConfirm === true) {
        this.startExport()
        return
      }

      this.$apopup.base({
        title: this.$t('export_excel.confirm'),
        content: this.$t('pager.total', { total: this.dataTotalQuantity }),
        applyCallback: this.startExport,
      })
    },
    cleanupData() {
      this.rawData = []
    },
    async startExport() {
      this.cleanupData()
      this.loading = true
      try {
        await this.fetchData()
      } catch (error) {
        console.error(error)
        this.$snotify.error(
          this.$t('export_excel.error.index_failure'),
          this.$t('export_excel.failure')
        )
        this.loading = false
        return
      }

      await this.handleXlsx()
      this.loading = false
    },
    async fetchData() {
      const result = await this.listApi.exportApi(this.exportParams)
      this.setResult(result)
      await this.$nextTick()

      if(this.hasNext) {
        this.page++
        await this.fetchData()
      }
    },
    setResult(result) {
      if(result.pager) {
        this.pager = result.pager
        this.rawData = this.rawData.concat(result.data)
      }

      else {
        this.pager = null
        this.rawData = this.rawData.concat(result.data)
      }
    },
  },
  computed: {
    hasNext() {
      if(!this.pager) return false
      return this.pager.has_next == true
    },
    exportParams() {
      const params = window.eagleLodash.cloneDeep(this.indexParams)
      params.exportMode = true

      if(this.listPager) {
        params.page = this.page
        params.per = this.exportExcelConfig.indexPerPage || 20
      }

      else {
        params.pager = false
      }
      return params
    },
    indexParams() {
      return this.$store.getters[`list/${this.listKey}/indexParams`]
    },
    listApi() {
      return this.$store.getters[`list/${this.listKey}/api`]
    },
    listPager() {
      return this.$store.getters[`list/${this.listKey}/pager`]
    },
    dataTotalQuantity() {
      return this.$store.getters[`list/${this.listKey}/dataTotalQuantity`]
    },
    listConfig() {
      return this.$store.getters[`list/${this.listKey}/config`]
    },
    exportExcelConfig() {
      return this.$store.getters[`list/${this.listKey}/exportExcelConfig`]
    },
  },
}
